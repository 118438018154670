import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button';
import { VireLogoIcon } from './Icons';
import React from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

VVNavbar.propTypes = {
    employerScrollToRef: PropTypes.object.isRequired
};

export function VVNavbar({employerScrollToRef}) {
    const location = useLocation();

    const isEmployersPage = location.pathname === '/employers';

    const handleButtonClick = () => {
        console.log("Is Employer Page: ", isEmployersPage)
        if (isEmployersPage) {
            employerScrollToRef.current?.scrollIntoView({ behavior: 'smooth' })
            return
        } else {
            window.location.href = "https://app.joinvire.com"
        }
    }

    return  (
        <div>
            <div className={"navbar-vire"}>
                <Navbar collapseOnSelect expand="lg">
                        <Navbar.Brand href="/" className="vire-logo">
                            <VireLogoIcon size="35"/>
                            <Navbar.Text className="vire-logo-text">Vire</Navbar.Text>
                        </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="justify-content-end" style={{ width: "100%" }}>
                            {/* <Nav.Link className="vire-nav-link" href="/about">About</Nav.Link> */}
                            <Nav.Link className="vire-nav-link" href="/employers">Employers</Nav.Link>
                            <Nav.Link className="vire-nav-link" href="/candidates">Candidates</Nav.Link>
                            <Nav.Link className="vire-nav-link" href="/blog">Blog</Nav.Link>
                            <Button variant="outline-secondary" className="vire-sign-up-button vire-category-button vire-nav-button align-center" onClick={() => handleButtonClick()}>{isEmployersPage ? "Start now" : "Sign in"}</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    );
}